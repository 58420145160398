const routerM = [
  {
    path: "/",
    name: "Home",
    redirect: "/Mobile/Home",
  },
  {
    path: "/Mobile/Home",
    name: "MobileHome",
    component: () => import("@/components/MobileHome.vue"),
  },
  {
    path: "/Mobile/About",
    name: "MobileAbout",
    component: () =>
      import(/* webpackChunkName: "nameList" */ "@/components/MobileAbout.vue"),
  },
];
export default routerM;
