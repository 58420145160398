import Vue from "vue";
import VueRouter from "vue-router";
import routerM from "./routerM"
import routerPC from "./routerPC"

Vue.use(VueRouter);

// const routes = [
//   {
//     path: "/",
//     name: "主页",
//     redirect: "/Mobile/Home",
//   },
//   {
//     path: "/PC/Home",
//     name: "PCHome",
//     component: () => import("@/components/PCHome.vue"),
//   },
//   {
//     path: "/PC/About",
//     name: "PCAbout",
//     component: () =>
//       import(/* webpackChunkName: "nameList" */ "@/components/PCAbout.vue"),
//   },
//   {
//     path: "/Mobile/Home",
//     name: "MobileHome",
//     component: () => import("@/components/MobileHome.vue"),
//   },
//   {
//     path: "/Mobile/About",
//     name: "MobileAbout",
//     component: () =>
//       import(/* webpackChunkName: "nameList" */ "@/components/MobileAbout.vue"),
//   },
// ];


const ISMOBILE = function () {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
  };
const router = new VueRouter({
  mode: "hash",
  linkActiveClass: "active",
  // base: process.env.BASE_URL,
  //routes,
  scrollBehavior: () => ({ y: 0 }),
  fallback: false,
  // 这里判断后选择使用移动端的路由还是pc端的路由
  routes: ISMOBILE() ? routerM : routerPC,
});

export default router;
