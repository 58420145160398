const routerPC = [
  {
    path: "/",
    name: "Home",
    redirect: "/PC/Home",
  },
  {
    path: "/PC/Home",
    name: "PCHome",
    component: () => import("@/components/PCHome.vue"),
  },
  {
    path: "/PC/About",
    name: "PCAbout",
    component: () =>
      import(/* webpackChunkName: "nameList" */ "@/components/PCAbout.vue"),
  },
];
export default routerPC;