import Vue from "vue";
import "@/assets/styles/common.scss";
import "./assets/js/rem";
import router from "./router/index.js";
import "@/assets/js/abText/main.js";
import App from "./App.vue";

import Beyla from "@shareit/beyla";

Vue.config.productionTip = false;

const beylaInstance = new Beyla({
  appId: "fe-skWebsite",
  strict: false,
});

Vue.prototype.$beyla = beylaInstance;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");