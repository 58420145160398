/*
 * @Author: webkong
 * @Date: 2021-11-29 19:10:13
 * @Last Modified by: webkong
 * @Last Modified time: 2021-11-29 21:15:56
 */
var md5 = require("md5");
window.getABTestId = function (cloudConfig, deviceId) {
  if (cloudConfig == null) {
    cloudConfig = "";
  }

  var baseStr = deviceId + cloudConfig;
  console.log("🚀 ~ file: main.js ~ line 31 ~ getABTestId ~ baseStr", baseStr);
  var md5Str = md5(baseStr);
  console.log("🚀 ~ file: main.js ~ line 32 ~ getABTestId ~ md5Str", md5Str);
  var hashCode = calABTestHashCode(md5Str);
  console.log("🚀 ~ file: main.js ~ line 33 ~ getABTestId ~ hashCode", hashCode);
  var testId;
  if (hashCode >= 0 && hashCode < 100) {
    testId = hashCode;
  } else if (hashCode > -100 && hashCode < 0) {
    testId = Math.abs(hashCode);
  } else {
    var str = String(hashCode);
    var len = str.length;
    testId = parseInt(str.substring(len - 2, len));
  }
  testId += 1;
  try {
    window.localStorage.setItem("ktestId", testId);
  } catch (error) {console.log(error)}
  return testId;
};

function calABTestHashCode(value) {
  var result = 0;
  var len = value.length;
  var divisor = 4294967296;
  if (len == 0) return result;
  var prime = 31;
  for (var i = 0; i < len; i++) {
    var item = value.charCodeAt(i);

    console.log("🚀 ~ file: main.js ~ line 60 ~ calABTestHashCode ~ item", item);
    result = result * prime + item;
    console.log("🚀 ~ file: main.js ~ line 61 ~ calABTestHashCode ~ result", result);
    result = result % divisor;
    console.log("🚀 ~ file: main.js ~ line 63 ~ calABTestHashCode ~ result", result);
  }

  return result;
}
