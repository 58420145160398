const rem = (win) => {
  let h;
  const docEl = document.documentElement;
  function setUnitA() {
    const w = docEl.getBoundingClientRect().width;
    let r = 20;
    if (w === 412) {
      r = (412 / 360) * 20;
    }
    win.rem = w / r;
    docEl.style.fontSize = win.rem + "px";
  }
  win.addEventListener(
    "resize",
    () => {
      clearTimeout(h);
      h = setTimeout(setUnitA, 300);
    },
    false
  );
  win.addEventListener(
    "pageshow",
    (e) => {
      if (e.persisted) {
        clearTimeout(h);
        h = setTimeout(setUnitA, 300);
      }
    },
    false
  );
  setUnitA();
};
rem(window);
