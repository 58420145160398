<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <router-view />
  </div>
</template>

<script>
import { getGup } from "@/assets/js/utils.js";
export default {
  name: "App",
  data() {
    return {
    };
  },
  mounted() {
    this.$beyla.report({
      pveCur: "/fe-skWebsite/home/x/x",
      eventName: "in_page",
      ab: getGup(),
      extras: "online_1_0110",
      type: "home",
    });
  },
  methods: {
  },
};
</script>

<style>
#app {
  font-family: PingFang, Helvetica-Neue, Helvetica, Microsoft YaHei, Avenir,
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
