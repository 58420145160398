import Vue from "vue";
const getAllUrlParams = () => {
  const u = window.location.search,
    reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"),
    r = u.substr(u.indexOf("?") + 1).match(reg);
  return r != null ? r[2] : "";
};
const getGup = () => {
  let testId = window.localStorage.getItem("ktestId");
  if (!testId) {
    testId = window.getABTestId(
      "sharekaro-website",
      Vue.prototype.$beyla.beylaId
    );
  }
  // set group
  if (testId >= 1 && testId <= 33) {
    // a
    return "a";
  } else if (testId >= 34 && testId <= 66) {
    // b
    return "b";
  } else if (testId >= 67 && testId <= 99) {
    // c
    return "c";
  } else {
    return "d";
  }
};
export { getAllUrlParams, getGup };
